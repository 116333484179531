/* eslint-disable eqeqeq */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Navbar from "./Header/Navbar";
import { getContestList, deleteContest, addDictionary, getEditDictionary, deleteDictionaryData } from "../api/apiService";
import { getReadableDateTime } from "../utils/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Modal from 'react-modal';
import Pagination from "../Components/Pagination/Pagination";


const SessionStorage_State_Key = "CreateContest";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: "35vh",
    width: "30%",
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#E5EDE8",

  },
};

class CreateContest extends Component {


  state = {
    allContests: [],
    currentPage: 1,
    pageOffset: 3,
    contestItemLimit: 50,
    searchQuery: "",
    rendered: false,
    navigated: false,
    totalPages: 0,
    LoadingIs: false,
    countryList: [],
    indexCount: 0,
    dynCount: 10,
    dictionaryName: "",
    dictionaryValue: "",
    loaderAdd: false,
    modal: false,
    editName: "",
    editValue: "",
    editId: "",
    tableContentViewID: "",
    booleanValue: false,
    error: false
  };


  componentDidMount() {
    this.initialized();
  }

  initialized() {
    let sessionState = sessionStorage.getItem(SessionStorage_State_Key);
    let contestItemLimit = 10;
    if (sessionState) {
      sessionState = JSON.parse(sessionState);
      if (sessionState.navigated && sessionState.currentPage !== 1) {
        let currentState = this.state;
        Object.assign(currentState, sessionState);
        this.setState({ ...currentState, rendered: true, navigated: false });
        console.log()
        return;
      }
    }
    this.getAllContest(undefined, contestItemLimit);
    this.setState({ rendered: true, navigated: false });
  }

  sessionExpired() {
    this.setState({ LoadingIs: false });
    toast.warn("Your session expired, You have been logged out!", {
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    localStorage.clear();
    this.props.history.replace({
      pathname: "/",
      state: { cameFrom: "/CreateContest", state: this.state },
    });
  }


  getLimitSet=(e)=>{
    console.log(e.target.value,"jjjjjjjjjjjjjj")
    this.setState({
      contestItemLimit:e.target.value,
    dynCount: e.target.value,

    })
   this.getAllContest(this.state.currentPage,e.target.value)
  }

  getAllContest = async (
    currentPage = this.state.currentPage,
    limit = this.state.contestItemLimit
  ) => {
    // console.log(currentPage, limit, "KKKKK")
    this.setState({ LoadingIs: true });
    if (this.state.searchQuery.length > 1) {
      this.setState({ contestItemLimit: limit }, () => {
        this.searchContest({
          target: { value: this.state.searchQuery },
          currentPage,
          limit,
        });
      });
      return;
    }

    let response = await getContestList(
      this.state.searchQuery,
      currentPage,
      limit,
    );
    if (response.status === 2) {
      this.sessionExpired();
      return;
    } else if (response.status === 1) {
      // console.log(response, "KKKKK pagesssssssssss")
      this.setState({
        allContests: response?.data ? response.data : [],
        totalPages: response?.totalPages,
        currentPage,
        contestItemLimit: limit,
        LoadingIs: false,
      });
    } else {
      toast.error(response.message);
      return;
    }
  };

  


  searchContest = async ({
    target,
    currentPage = 1,
    limit = this.state.contestItemLimit,
  }) => {
    let searchQuery = target.value;
    let pageOffset = this.state.pageOffset;

    if (currentPage === 1) {
      pageOffset = 3;
    }

    this.setState({ searchQuery, pageOffset });

    if (searchQuery === "") {
      this.getAllContest();
      return;
    } else if (`${searchQuery}`.length < 1) {
      return;
    }

    let response = await getContestList(
      // currentPage, limit,
      this.state.searchQuery, 1, 10);
    if (response.status === 2) {
      this.sessionExpired();
      return;
    } else if (response.status === 1) {
      this.setState({
        allContests: response?.data ? response.data : [],
        totalPages: response?.totalPages,
        currentPage,
        contestItemLimit: limit,
        LoadingIs: false,
      });
    } else {
      toast.error(response.message);
      return;
    }
  };

  getContestStatus(contestItem) {
    if (contestItem == 3) {
      return <span class="cancel">Cancelled</span>;
    } else if (contestItem == 4) {
      return <span class="Ended">Contests Ended</span>;
    } else if (contestItem == 2) {
      return (
        <span class="live text-orange">
          <span className="dot"></span>Live
        </span>
      );
    } else {
      return <span class="upcoming">Upcoming</span>;
    }
  }


  handleTextChange(e) {
    this.setState({
      dictionaryName: e.target.value,
      error: false
    })
  }

  handleSubmit = async () => {
    this.setState({
      loaderAdd: true,
    })
    const name = this.state.dictionaryName
    const value = this.state.dictionaryValue
    if (name === "" && value === "") {
      this.setState({
        error: true,
        loaderAdd: false
      })
    } else {
      let payload = {
        name: name,
        value: value
      }
      const response = await addDictionary(payload);
      if (response?.status === 1) {
        toast.success(response?.message)
        this.setState({
          loaderAdd: false,
          dictionaryName: "",
          dictionaryValue: ""
        })
        this.initialized();

      } else {
        toast.error(response?.message)
        this.setState({
          loaderAdd: false,
        })
      }
    }

  }


  EditFunction = async (id, name, value) => {
    if (!name) {
      return toast.error("invalid filed found !")
    }
    const editData = await getEditDictionary(id, name, value);
    if (editData.status === 1) {
      toast.success(editData.message)
      this.setState({
        modal: false
      })
      this.initialized()
    } else {
      toast.error(editData.message)
    }
  }


  handleTextEdit = (e) => {
    this.setState({
      editName: e.target.value
    })
  }

  handleValueEdit = (e) => {
    this.setState({
      editValue: e.target.value
    })
  }

  handleValueChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      this.setState({ dictionaryValue: inputValue });
    }
    // this.setState({
    //   dictionaryValue: e.target.value
    // })
  }


  deleteDictionary = async (id) => {
    const deleteData = await deleteDictionaryData(id);
    if (deleteData.status === 1) {
      toast.success(deleteData?.message)
      this.initialized()
    } else {
      toast.error(deleteData.message)
    }
  }

  contestView = (id) => {
    this.setState({
      tableContentViewID: id,
      booleanValue: !this.state.booleanValue
    })
  }

  getEditShow(contestItem) {
    let startTime = new Date(contestItem.startTime);
    let endTime = new Date(contestItem.endTime);
    let timeNow = new Date();

    let current_time = moment(new Date()).format("DD/MM/YYYY  HH:mm:ss");
    let start_time = moment(contestItem.startTime).format("DD/MM/YYYY  HH:mm:ss");
    let startWithCurrentDiff = moment(start_time, "DD/MM/YYYY HH:mm:ss").diff(
      moment(current_time, "DD/MM/YYYY HH:mm:ss")
    );

    if (!contestItem.contest_status && startWithCurrentDiff <= 0) {
      return true;
    } else if (endTime < timeNow) {
      return true;
    } else if (timeNow < endTime && timeNow > startTime) {
      return true;
    } else {
      return false;
    }
  }

  deleteContest = async (index, contestId) => {
    let { allContests } = this.state;

    let response = await deleteContest(contestId);
    if (response.status === 2) {
      this.sessionExpired();
      return;
    } else if (response.status !== 1) {
      toast.warn("Contest not deleted, try later");
      return;
    }
    allContests.splice(index, 1);
    this.setState({ allContests: allContests });

    toast.success(response.msg, {
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  handlePageClick = (data) => {
    // console.log(data, "KKKKK")
    let selectedData = data.selected + 1;
    this.setState(
      { currentPage: selectedData, indexCount: data.selected },
      () => {
        this.getAllContest(selectedData);
      }
    );
  };

  updateSessionState = () => {
    // console.log(this.state.rendered, "<CreateContest>")
    if (this.state.rendered) {
      sessionStorage.setItem(SessionStorage_State_Key, JSON.stringify(this.state));
    }
  };

  updateSessionStateOnNavigation = (navigated = false) => {
    let state = this.state;
    state = { ...state, navigated };
    sessionStorage.setItem(SessionStorage_State_Key, JSON.stringify(state));
  };

  render() {
    this.updateSessionState();
    const { allContests, LoadingIs, indexCount, dynCount } = this.state;

    return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="header">
            <div className="container-fluid">
              <div className="header-body">
                <div className="row align-items-end">
                  <div className="col-lg-12">
                    <h6 className="header-pretitle"> List </h6>
                    <h1 className="header-title">Dictionary</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="d-flex align-items-center my-4">
              <div className="col-md-4">
                <textarea
                  type="text"
                  aria-label="Text input with dropdown button"
                  name="dictionarName"
                  // className="form-control form-control-appended col-md-3"
                  className={Boolean(this.state.error) ? "form-control form-control-appedned border-danger p-0 m-0" : "form-control p-0"}
                  placeholder="Enter your Dictionary Word"
                  value={this.state.dictionaryName}
                  onChange={(e) => this.handleTextChange(e)}
                />
                {Boolean(this.state.error) && (
                  <p className='text-danger mt-2'>{"Please fill the name"}</p>
                )}
              </div>
              <div className="col-md-4">
                <textarea
                  type="text"
                  aria-label="Text input with dropdown button"
                  name="value"
                  // className="form-control form-control-appended col-md-3 mx-4"
                  className={Boolean(this.state.error) ? "form-control form-control-appedned border-danger p-0 me-2" : "form-control p-0 me-2"}
                  placeholder="Enter your value"
                  value={this.state.dictionaryValue}
                  onChange={(e) => this.handleValueChange(e)}
                />
                {Boolean(this.state.error) && (
                  <p className='text-danger mt-2'>{"Please fill the value"}</p>
                )}
              </div>
              <div className="col-md-4">
                {
                  !this.state.loaderAdd ?
                    <button className="btn btn-primary mx-3 ms-4"
                      onClick={() => this.handleSubmit()}
                    >
                      Add
                    </button> : <div class="spinner-border mx-2" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                }
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <h4 className="card-header-title">List</h4>
                      </div>
                      <form className="d-flex">
                        <div className="input-group input-group-flush input-group-merge">
                          <input
                            type="search"
                            className="form-control form-control-prepended search"
                            placeholder="Search"
                            value={this.state.searchQuery}
                            onChange={this.searchContest}
                          />
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <span className="fe fe-search" />
                            </div>
                          </div>
                        </div>
                            <select class="form-control slect_form" onClick={(e)=>this.getLimitSet(e)}>
                              <option value="50">Show 50</option>
                              <option value="70">Show 70</option>
                              <option value="100">Show 100</option>
                            </select>
                        {/* <div className="dropdown">
             <select
              className="form-control slect_form"
              value={this.state.contestItemLimit}
              onChange={(event) => {
               this.setState({ dynCount: event.target.value });
               this.getAllContest(this.state.currentPage, event.target.value);
              }}
             >
              <option value="50">Show 50</option>
              <option value="70">Show 70</option>
              <option value="100">Show 100</option>
             </select>
            </div> */}
                      </form>
                    </div>
                  </div>
                  <div className="table-responsive mb-0 table_height scroll_bar mb-0 x_scroll_width contest_table">
                    <table className="table table-hover table-sm card-table table-center">
                      <thead>
                        <tr className="colorSetting" >
                          <th>#</th>
                          <th>
                            <a href="#/" class="text-muted list-sort" data-sort="tables-row">
                              Words
                            </a>
                          </th>
                          <th className="text-center">
                            <a href="#/" class="text-muted list-sort" data-sort="tables-row">
                              Meaning
                            </a>
                          </th>
                          {/* <th className="text-center">
              <a href="#/" class="text-muted list-sort" data-sort="tables-row">
               Entry fees
              </a>
             </th> */}
                          {/* <th className="text-center">
              {" "}
              <a href="#/" class="text-muted list-sort" data-sort="tables-row">
               Min Participation
              </a>
             </th> */}
                          {/* <th className="text-center">
              <a href="#/" class="text-muted list-sort" data-sort="tables-row">
               Max Participation
              </a>
             </th> */}
                          {/* <th className="text-center">
              <a href="#/" class="text-muted list-sort" data-sort="tables-row">
               Duration
              </a>
             </th> */}
                          <th className="text-center">
                            <a href="#/" class="text-muted list-sort" data-sort="tables-row">
                              CreatedAt
                            </a>
                          </th>
                          <th className="text-center">
                            <a href="#/" class="text-muted list-sort" data-sort="tables-row">
                              UpdatedAt
                            </a>
                          </th>
                          <th></th>
                        </tr>
                      </thead>

                      {LoadingIs ? (
                        <tbody>
                          <tr>
                            <td colSpan="12">
                              <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {allContests.length > 0 ? (
                            allContests.map((eachItem, index) => (
                              <tr
                              // style={{
                              //  background: eachItem.status == 2 ? "#00000" : "",
                              // //  color: eachItem.status == 2 ? "#ff0000" : "",

                              // }}

                              >
                                <Link
                                  to={{
                                    // pathname: "/Viewcontest",
                                    // search: `contestId=${eachItem.id}`,
                                  }}
                                  //  onClick={() => {
                                  //   this.updateSessionStateOnNavigation(true);
                                  //  }}
                                  className="display-content textwhilte"
                                  //  className={eachItem.status==2?"display-content textwhilte":"display-content"}

                                  key={eachItem.id + index}
                                >
                                  <td>{indexCount * dynCount + (index + 1)}</td>
                                  {/* <td>{indexCount}</td> */}

                                  <td>{eachItem?.name}</td>
                                  <td onClick={() => this.contestView(eachItem?.id)} className={eachItem?.id == this.state.tableContentViewID && this.state.booleanValue ? "card  my-2 " : ""} >{eachItem?.id == this.state.tableContentViewID && this.state.booleanValue ? "" : eachItem?.value.slice(0, 90) + "..."}
                                    {eachItem?.id == this.state.tableContentViewID && this.state.booleanValue ? <p className=" " style={{ overflowWrap: "break-word" }}>{eachItem?.value}</p> : ""}
                                  </td>
                                  <td className="text-center">
                                    {getReadableDateTime(eachItem?.createdAt)}
                                  </td>
                                  <td className="text-center">
                                    {getReadableDateTime(eachItem?.updatedAt)}
                                  </td>
                                </Link>
                                <td className="text-right">
                                  <div className="dropdown">
                                    <a
                                      href="#/"
                                      className="dropdown-ellipses dropdown-toggle"
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <i
                                        className="fe fe-more-vertical"
                                        style={{ color: "#12263F" }}
                                      />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <Link
                                        onClick={() => [
                                          this.setState({
                                            modal: true,
                                            editName: eachItem?.name,
                                            editValue: eachItem?.value,
                                            editId: eachItem?.id
                                          }),

                                        ]}
                                        className="dropdown-item"
                                      // key={`details-${eachItem.id}`}
                                      >
                                        Edit
                                      </Link>
                                      {
                                        <>
                                          {this.getEditShow(eachItem) ? (
                                            ""
                                          ) : (
                                            <></>
                                          )}
                                          <Link
                                            onClick={() => {
                                              this.deleteDictionary(eachItem?.id);
                                            }}
                                            className="dropdown-item"
                                          >
                                            Delete
                                          </Link>
                                        </>
                                      }
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td>No data Found...</td>
                            </tr>
                          )}
                        </tbody>
                      )}
                    </table>
                    
                  </div>
                </div>
              </div>

              {/* modal */}

              <Modal
                isOpen={this.state.modal}
                // isOpen={true}

                onRequestClose={() => this.setState({ modal: false })}
                style={customStyles}
              >
                <div className="container row mainModal" >
                  <div className="col-md-12 ">
                    <input
                      type="text"
                      aria-label="Text input with dropdown button"
                      name="password"
                      className="form-control form-control-appended col-md-12 "
                      placeholder="Enter your Dictionary"
                      value={this.state.editName}
                      onChange={(e) => this.handleTextEdit(e)}
                    />
                    <input
                      type="text"
                      aria-label="Text input with dropdown button"
                      name="password"
                      className="form-control form-control-appended col-md-12 my-3"
                      placeholder="Enter your Dictionary"
                      value={this.state.editValue}
                      onChange={(e) => this.handleValueEdit(e)}
                    />
                    <div className="mt-5 text-center">
                      <button className="btn  btn-primary mx-3 " onClick={() => this.EditFunction(this.state.editId, this.state.editName, this.state.editValue)}>
                        Add
                      </button>
                      <button className="btn btn-danger mx-3 " onClick={() => this.setState({ modal: false })}>
                        Cancle
                      </button>
                    </div>
                  </div>
                </div>

              </Modal>


              {/* <div className="col-12">
                {this.state.totalPages > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div> */}
            </div>
            <Pagination
              itemsPerPage={10}
              onPageChange={this.handlePageClick}
              // pageCount={step === 0 ? dealerDetails?.pageCount : driverDetails?.pageCount}
              pageCount={this.state.totalPages}
            />
          </div>

        </div>
      </>
    );
  }
}

export default withRouter(CreateContest);
