import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { logIn } from "../api/apiService";
import { AUTH_TOKEN } from "../store/sessionStogrageKey";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class SignIn extends Component {
	state = {
		email: "",
		password: "",
		passwordType: true,
	};

	componentDidMount() {
		if (localStorage.getItem(AUTH_TOKEN)) {
			this.props.history.replace("/");
		}
	}

	showPassword = () => {
		let passwordType = this.state.passwordType;
		if (passwordType) {
			this.setState({ passwordType: false });
		} else {
			this.setState({ passwordType: true });
		}
	};

	isPasswordType = () => {
		let passwordType = this.state.passwordType;
		if (passwordType) return "password";
		return "text";
	};

	handleTextChange = (event) => {
		const target = event.target;
		this.setState({ [target.name]: target.value });
	};

	handleValidation(email, password) {
		if (!email || !password) {
			toast.error("Input fields can't be empty");
			return false;
		}
		return true;
	}

	handleLoginSubmit = async (event) => {
		event.preventDefault();
		localStorage.removeItem(AUTH_TOKEN);
		const { email, password } = this.state;
		if (!this.handleValidation(email, password)) return;
		const response = await logIn(email, password);
		if (response.status === 1) {
			localStorage.setItem(AUTH_TOKEN, response?.token);
			toast.success(response?.message)
			console.log(response, "dddd")
			// if (this.props?.location.state) {
			// 	this.props.history.replace({
			// 		pathname: this.props?.location.state.cameFrom,
			// 		search: this.props?.location.search ?? "",
			// 		state: { state: this.props?.location.state?.state },
			// 	});
			// 	return;
			// }
			this.props.history.replace("/dashboard");
		} else {
			toast.error(response.message, {
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	render() {
		return (
			<>
				<div className="d-flex align-items-center vh-100">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12 col-md-5 col-xl-4 my-5">
								<h1 className="display-4 text-center mb-3"> Login </h1>
								<form onSubmit={this.handleLoginSubmit}>
									<div className="form-group">
										<label>Email Address</label>
										<input
											type="email"
											name="email"
											className="form-control"
											placeholder="name@address.com"
											value={this.state.email}
											onChange={this.handleTextChange}
										/>
									</div>
									<div className="form-group">
										<div className="row">
											<div className="col mx-3">
												<label>Password</label>
											</div>
										</div>{" "}
										<div className="input-group input-group-merge">
											<input
												type={this.isPasswordType()}
												name="password"
												className="form-control form-control-appended"
												placeholder="Enter your password"
												value={this.state.password}
												onChange={this.handleTextChange}
											/>
											<div className="input-group-append">
												<span className="input-group-text">
													<i
														className={this.state.passwordType ? "fa fa-eye-slash" : "fa fa-eye"}
														onClick={this.showPassword}
													/>
												</span>
											</div>
										</div>
									</div>
									<button className="btn btn-lg btn-block btn-primary mb-3">
										{" "}
										Log in{" "}
									</button>
								</form>
							</div>
						</div>
					</div>
					{/* <ToastContainer position="top-center" autoClose={2000} /> */}
				</div>
			</>
		);
	}
}

export default withRouter(SignIn);
