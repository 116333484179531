import React, { Component } from "react";
import Navbar from "../Pages/Header/Navbar";
import { Link } from "react-router-dom";
import moment from "moment";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDashboardData, getUsersList, getContestList } from "../api/apiService";

export default class Dashboard extends Component {
	state = {
		startDate: "",
		endDate: "",
		today: "",
		Active: [],
		data: [],
		dictionary: ""
	};

	componentDidMount() {
		const d = new Date();
		this.setState(
			{
				today: d,
			},
			() => {
				this.handleDashboardData();
			}
		);
	}

	handleDateValidation = (startDate) => {

	};

	handleDashboardData = async () => {
		const data = await getUsersList();
		const ActiveUser = data.data.filter((e) => e?.status == true)
		this.setState({
			data: data?.data,
			Active: ActiveUser
		})

		const dictionaryData = await getContestList();
		// console.log(dictionaryData, "KKKKK")
		this.setState({
			dictionary: parseInt(dictionaryData.totalPages * 10)
		})
	};

	render() {
		const { data } = this.state;
		return (
			<>
				<Navbar />
				<div className="main-content">
					<div className="header">
						<div className="container-fluid">
							<div className="header-body">
								<div className="row align-items-end">
									<div className="col ml-3">
										<h6 className="header-pretitle">Overview</h6>
										<h1 className="header-title">Dashboard</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid ">
						{/* <div className="row mb-4 ml-2">
							<div
								className="col-12 d-flex"
								data-toggle="collapse"
								href="#multiCollapseExample1"
								role="button"
								aria-expanded="false"
								aria-controls="multiCollapseExample1"
							>
								<h4
									style={{
										color: "#95AAC9",
										border: "1px solid #95AAC9",
										borderRadius: ".375rem",
										padding: "5px",
									}}
								>
									FILTER <i className="fe fe-chevron-down ml-2"></i>
								</h4>
							</div>
						</div> */}
						<div
							className="row collapse multi-collapse ml-2"
							id="multiCollapseExample1"
						>
							<div
								className="col-12 mb-3"
								style={{ borderBottom: "1px solid #E3EBF6" }}
							>
								<h6 style={{ color: "#95AAC9" }}>FILTER BY DATE</h6>
							</div>
							<div className="col-5 mx-2">
								<div className="form-group">
									<label>From Date</label>
									<Flatpickr
										value={this.state.startDate}
										className="form-control"
										options={{
											dateFormat: "d-m-Y",
											maxDate: this.state.today,
										}}
										onChange={(date) => {
											let valid = this.handleDateValidation(
												new Date(date).toISOString()
											);
											if (valid) {
												this.setState({
													startDate: new Date(date).toISOString(),
													endDate: new Date(date).toISOString(),
												});
											} else {
												this.setState({
													startDate: new Date(date).toISOString(),
												});
											}
										}}
									/>
								</div>
							</div>
							<div className="col-5 mx-3">
								<div className="form-group">
									<label>To Date</label>
									<Flatpickr
										value={this.state.endDate}
										className="form-control"
										options={{
											minDate: this.state.startDate,
											maxDate: this.state.today,
											dateFormat: "d-m-Y",
										}}
										onChange={(date) => {
											this.setState({ endDate: new Date(date).toISOString() });
										}}
									/>
								</div>
							</div>
							<div className="col-2">
								<div className="form-group">
									<label style={{ marginBottom: "3.4rem" }}></label>
									<button
										onClick={() => {
											this.handleDashboardData();
										}}
										className="btn btn-primary mr-3"
									>
										Submit
									</button>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-lg-6 col-xl mx-2">
								<div className="card">
									<div className="card-body">
										<Link to={{ pathname: "/Dashboard" }}>
											<div className="row align-items-center">
												<div className="col">
													<h6 className="text-uppercase text-muted mb-2">
														{" "}
														TOTAL NUMBER OF USERS
													</h6>
													<span className="h2 mb-0">
														{" "}
														{data?.length || 0}
													</span>
												</div>

												<div className="col-auto">
													<span className="h2 fe fe-user text-muted mb-0" />
												</div>
											</div>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xl mx-2">
								<div className="card">
									<div className="card-body">
										<Link to={{ pathname: "/Dashboard" }}>
											<div className="row align-items-center">
												<div className="col">
													<h6 className="text-uppercase text-muted mb-2">
														{" "}
														NUMBER OF ACTIVE USERS
													</h6>
													<span className="h2 mb-0">
														{" "}
														{this?.state?.Active?.length || 0}

													</span>
												</div>
												<div className="col-auto">
													<span className="h2 fe fe-user text-muted mb-0" />
												</div>
											</div>
										</Link>
									</div>
								</div>
							</div>

							<div className="col-12 col-lg-6 col-xl mx-2">
								<div className="card">
									<div className="card-body">
										<Link to="#" >
											<div className="row align-items-center">
												<div className="col">
													<h6 className="text-uppercase text-muted mb-2">
														{" "}
														Total Number of Words
													</h6>
													{
														console.log(this.state.dictionary, 'MMMMMMMMMMMMMMMMMMMMMMM')
													}
													{
														this.state.dictionary !== "" ?
															(
																<span className="h2 mb-0">
																	{
																		(this.state.dictionary || 0)
																	}
																</span>
															)
															:
															(
																<span className="h4 mb-0">
																	Loading...
																</span>
															)
													}
												</div>
												<div className="col-auto">
													<span className="h2 fe fe-book text-muted mb-0" />
												</div>
											</div>
										</Link>
									</div>
								</div>
							</div>

							{/* <div className="col-12 col-lg-6 col-xl mx-2">
								<div className="card">
									<div className="card-body">
										<Link to={{ pathname: "/CreateContest" }}>
											<div className="row align-items-center">
												<div className="col">
													<h6 className="text-uppercase text-muted mb-2">
														{" "}
														Past Contests
													</h6>
													<span className="h2 mb-0">
														{" "}
														{data?.pastContest || 0}{" "}
													</span>
												</div>
												<div className="col-auto">
													<span className="h2 fe fe-user text-muted mb-0" />
												</div>
											</div>
										</Link>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
				<ToastContainer position="top-center" autoClose={2000} />
			</>
		);
	}
}
